import React, { useState, useContext, useEffect } from "react";
import { downloadFile } from "../../../../../../../context/firebase/storageCrud";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Context } from "../../../../../../../context/Context";
import {
  authenticateWithEmailAndPassword,
  ConfigureFirebase,
} from "../../../../../../../context/firebase/firebaseconfig";
import UseContentDashboard from "../../../hooks/UseContentDashboard";

const PayloadViewer = ({
  open,
  onClose,
  transactiondata,
  keysEditables,
  keysVisibles,
  finalActionButtonLabel = "Completar Transacción",
  secondaryActionButtonLabel,
  onFinalActionButtonClicked = () => { },
  onSecondaryActionButtonClicked = () => { },
  refreshPage = () => { },
}) => {
  const [allPayload] = useState(transactiondata.payload);
  const [transactionid] = useState(transactiondata.transactionid);
  const [voucher] = useState(transactiondata.voucher);
  const [Paymentgateway] = useState(transactiondata.paymentgateway);
  const [newPayload, setNewPayload] = useState(transactiondata.payload);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [view, setView] = useState(1);
  const [voucherimage, setVoucherImage] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [comment, setComment] = useState("");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const datacontext = useContext(Context);

  useEffect(() => {
  }, [voucherimage]);

  useEffect(() => {
    if (Paymentgateway) {
      const DownloadVoucher = (storage) => {
        const filepath = datacontext.Sandbox
          ? "PhysicalVouchersSandox/" + Paymentgateway
          : "PhysicalVouchers/" + Paymentgateway;
        downloadFile(filepath, storage)
          .then((resp) => setVoucherImage(resp))
          .catch((e) => {
            console.log(e);
          });
      };

      const FirebaseConfigure = (res) => {
        try {
          const firebaseConfig = res.data.data.firebaseConfig;
          const firebaseUser = atob(res.data.data.userFirebase);
          const firebasePasword = atob(res.data.data.paswordFirebase);
          const { storage, auth } = ConfigureFirebase(firebaseConfig);
          authenticateWithEmailAndPassword(firebaseUser, firebasePasword, auth)
            .then(() => {
              DownloadVoucher(storage);
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (e) {
          console.log("Fallo al configurar firebase", e);
        }
      };
      datacontext.getFirebaseConfig(FirebaseConfigure, FirebaseConfigure);
    }
  }, [Paymentgateway]);

  const handleChange = (e, key) => {
    setNewPayload({ ...newPayload, [key]: e.target.value });
  };

  const handleDetailsChange = (index, key, value) => {
    const updatedDetails = newPayload.detalles.map((detalle, i) => {
      if (i === index) {
        return { ...detalle, [key]: value };
      }
      return detalle;
    });
    setNewPayload({ ...newPayload, detalles: updatedDetails });
  };

  const onOkUpdate = (res) => {
    setIsLoading(false);
    setSnackbarMessage("Información actualizada correctamente!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    refreshPage()
  };

  const onFailUpdate = (res) => {
    setNewPayload(allPayload);
    setIsLoading(false);
    setSnackbarMessage("Error al actualizar información.");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  const toggleEdit = () => {
    if (isEditing) {
      setIsCommentModalOpen(true);
    } else {
      setIsEditing(true);
    }
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    datacontext.putNewPayload(onOkUpdate, onFailUpdate, {
      transactionid: transactiondata?.transactionid,
      payload: { ...newPayload, coment: newPayload.coment + ' ' + comment + '/' }
    });
    setNewPayload({ ...newPayload, coment: newPayload.coment + ' ' + comment + '/' })

    setIsEditing(false);
    setIsCommentModalOpen(false);
    setComment("")
  };

  const handleCloseCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  const handleCompleteTransaction = () => {
    const transactionData = {
      allPayload,
      newPayload,
      transactionid,
      voucher,
    };
    onFinalActionButtonClicked(transactionData);
    onClose();
  };

  const handleSecondaryButton = () => {
    const transactionData = {
      allPayload,
      newPayload,
      transactionid,
      voucher,
    };
    onSecondaryActionButtonClicked(transactionData);
    onClose();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const pdfUrl = `https://www.visitax.gob.mx/sitio/comprobante/impresion.php?comprobante=${voucher}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(pdfUrl);
    setSnackbarMessage("Link copiado a portapapeles!");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  const handlePdfLoad = () => {
    setLoadingPdf(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle variant="h3" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>Detalles de transacción
        <Typography variant="h6" component="span" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
          {transactiondata.payload.transaction_id}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* Navegación de Iconos para cambiar entre PDF y Contenido */}
        {(voucher || voucherimage) && (
          <Box
            display="flex"
            justifyContent="space-between"
            mb={2}
            alignItems="center"
          >
            {/* Link y Botón de Copiar (Solo se muestra en la vista PDF) */}
            {view == 2 && (
              <Box display="flex" alignItems="center">
                <Typography
                  component="a"
                  href={pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="secondary"
                  sx={{ textDecoration: "none", mr: 1 }}
                >
                  Descargar PDF
                </Typography>
                <Tooltip title="Copy link to clipboard">
                  <IconButton onClick={handleCopy} color="primary">
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {/* Botones para cambiar la vista */}
            <Box>
              <IconButton onClick={() => setView(1)} color="primary">
                <PreviewIcon />
              </IconButton>
              {voucher && (
                <IconButton onClick={() => setView(2)} color="secondary">
                  <PictureAsPdfIcon />
                </IconButton>
              )}
              {voucherimage && (
                <IconButton onClick={() => setView(3)} color="secondary">
                  <RequestPageIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        )}

        {/* Mostrar PDF, imagen del voucher o el contenido del payload según el estado */}
        {view === 1 ? (
          <Grid container spacing={2} sx={{ padding: "10px" }}>
            {keysVisibles.map((key) => {
              if (key !== "detalles") {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <TextField
                      fullWidth
                      label={key}
                      value={newPayload[key]}
                      onChange={(e) => handleChange(e, key)}
                      disabled={!isEditing || !keysEditables.includes(key)}
                      multiline={key === "extra_charges" || key === "coment"}
                      sx={{ mb: 2 }}
                    />
                  </Grid>
                );
              }
              return null;
            })}

            {keysVisibles.includes("detalles") && (
              <>
                {newPayload.detalles.map((detalle, index) => (
                  <Grid container spacing={2} key={index} sx={{ mb: 2, padding: "10px" }}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Persona {index + 1}</Typography>
                    </Grid>
                    {Object.keys(detalle).map((key) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          fullWidth
                          label={key}
                          value={detalle[key]}
                          onChange={(e) => handleDetailsChange(index, key, e.target.value)}
                          disabled={!isEditing || !keysEditables.includes(`detalles.${key}`)}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        ) : view === 2 ? (
          false ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={400}
            >
              <CircularProgress />
            </Box>
          ) : (
            <iframe
              src={pdfUrl}
              width="100%"
              height="500px"
              onLoad={handlePdfLoad}
              style={{ border: "none" }}
              title="Voucher PDF"
            />
          )
        ) : view === 3 ? (
          voucherimage ? (
            <Box display="flex" justifyContent="center">
              <img src={voucherimage} alt="Voucher" style={{ maxWidth: "100%" }} />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={400}
            >
              <CircularProgress />
            </Box>
          )
        ) : null}
      </DialogContent>
      <DialogActions>
        {keysEditables && (
          <Button variant="contained" onClick={toggleEdit}>
            {isEditing ? "Guardar" : "Editar"}
          </Button>
        )}
        {isEditing && (
          <Button variant="outlined" onClick={() => setNewPayload(allPayload)}>
            Revertir cambios
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCompleteTransaction}
          disabled={isEditing}
        >
          {finalActionButtonLabel}
        </Button>
        {secondaryActionButtonLabel && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSecondaryButton}
            disabled={isEditing}
          >
            {secondaryActionButtonLabel}
          </Button>
        )}
      </DialogActions>

      <Dialog
        open={isCommentModalOpen}
        onClose={handleCloseCommentModal}
        sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '90%' } }}
      >
        <DialogTitle>Comentario requerido</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: '20px' }}
            fullWidth
            label="¿Por qué se edito la transacción?"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommentModal}>Cancelar</Button>
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={!comment.trim()}
          >
            Guardar cambios
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default PayloadViewer;
