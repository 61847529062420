import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import {
  createDocument,
  readDocumentById,
  updateDocumentById,
} from "../../../context/puchdb/pouchdbData";
import { openpay3ds } from "../../../data/Datos";
import { uploadFile } from "../../../context/firebase/storageCrud";
import { ConfigureFirebase } from "../../../context/firebase/firebaseconfig";
import { authenticateWithEmailAndPassword } from "../../../context/firebase/firebaseconfig";
export const useOPPayment = ({ setPaymentFail, setWaitingResponse, setID }) => {
  const datacontext = useContext(Context);
  const dbId = "dataPayment";
  const navigate = useNavigate();
  const [state, setState] = useState(0);
  const [openpayOptions, setOpenPayOptions] = useState({
    openpayid: null,
    apikeyopenpay: null,
    sandboxmode: datacontext.Sandbox,
  });
  const [deviceSessionId, setDeviceSessionId] = useState(null);
  const [source_id, setSource_id] = useState(null);
  const [tokenid, setTokenid] = useState(null);
  const [advisorsActive, setAdvisorsActice] = useState(true);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];

  const ConfigureApiOpenPay = () => {
    try {
      datacontext.myConfig.allconfigArray.map((value) => {
        if (value.concept.includes("OPENPAYID")) {
          setOpenPayOptions((prev) => ({
            openpayid: value.configurationInfo,
            apikeyopenpay: prev.apikeyopenpay,
            sandboxmode: prev.sandboxmode,
          }));
        }
        if (value.concept.includes("PUBLICAPIKEYOPENPAY")) {
          setOpenPayOptions((prev) => ({
            openpayid: prev.openpayid,
            apikeyopenpay: value.configurationInfo,
            sandboxmode: prev.sandboxmode,
          }));
        }
        if (value.concept.includes("3dsAdvisorsActive")) {
          setAdvisorsActice(value.configurationInfo == 1 ? true : false);
        }
      });
    } catch (error) {
      console.error("Error configuring OpenPay API:", error);
      FailResponsePay("Error configuring Gateway. Try again.");
    }
  };

  const successCallbacktoken = async (response) => {
    try {
      setSource_id(response.data.id);
    } catch (error) {
      console.error("Error en successCallbacktoken:", error);
      FailResponsePay("Error processing the token response.");
    }
  };
  const errorCallbacktoken = (response) => {
    console.log(response);
    FailResponsePay(
      (txt[76]), false
    );
  };

  const CreateToken = () => {
    try {
      window.OpenPay.token.create(
        datacontext.card,
        successCallbacktoken,
        errorCallbacktoken,
        {
          use_3d_secure: false,
        }
      );
    } catch (e) {
      console.error("Error en CreateToken:", e);
      FailResponsePay(txt[76]);
    }
  };

  const success3Ds = async (res) => {
    try {
      if (
        res.data.method.includes("card") &&
        res.data.status.includes("completed")
      ) {
        CompletePay(res.data.id, true, "", null);
        return;
      }

      if (
        res.data.method.includes("card") &&
        (res.data.status.includes("in_progress") ||
          res.data.status.includes("charge_pending"))
      ) {
        const userDoc = { _id: dbId, ...datacontext.Data.Datos };
        try {
          createDocument(userDoc)
            .then((window.location.href = res.data.payment_method.url))
            .catch(
              updateDocumentById(dbId, userDoc)
                .then((window.location.href = res.data.payment_method.url))
                .catch(console.log())
            );
        } catch (err) {
          if (err.status === 409) {
            updateDocumentById(dbId, userDoc)
              .then((window.location.href = res.data.payment_method.url))
              .catch(console.log());
          }
        }
      }
    } catch (error) {
      console.error("Error en success3Ds:", error);
      FailResponsePay("Error processing 3DS.");
    }
  };

  const fail3Ds = (response) => {
    const failmessage = (txt[77])
    FailResponsePay(failmessage ? failmessage : "Error desconocido", false);
  };

  const AskFor3ds = () => {
    try {
      datacontext.postchargeop3ds(success3Ds, fail3Ds, {
        ...openpay3ds,
        //use_3d_secure: datacontext.LoggedUser ? advisorsActive : true,
        use_3d_secure: advisorsActive,
        redirect_url: window.location.hostname + "/TotalPayment",
        amount: datacontext.Data.Datos.importe,
        source_id: source_id,
        device_session_id: deviceSessionId,
        description: datacontext.Data.Datos.detalles[0].pasaporte,
        customer: {
          name: datacontext.card.holder_name,
          last_name: "",
          phone_number: datacontext.card.phone_number,
          email: datacontext.card.email,
        },
      });
    } catch (error) {
      console.error("Error en AskFor3ds:", error);
      FailResponsePay("Error starting the 3DS process.");
    }
  };

  const OKResponsePay = (comprobante) => {
    try {
      datacontext.setComprobante(comprobante);
      navigate("/PaymentConfirmation");
      setPaymentFail(null);
      setWaitingResponse(false);
    } catch (error) {
      console.error("Error en OKResponsePay:", error);
      FailResponsePay();
    }
  };
  const FailResponsePay = (message = (txt[77])) => {
    try {
      setPaymentFail(message);
      setWaitingResponse(false);
      setID(null);
      setState(0);
      ConfigureApiOpenPay();

      try {
        if (tokenid) {
          readDocumentById(dbId).then((doc) => {
            datacontext.UpdateData(doc);
            navigate("/TotalPayment");
          });
        }
      } catch (e) {
        console.log(e);
      }
    } catch (error) {
      console.error("Error en FailResponsePay:", error);
    }
  };

  const manageResponse = (response) => {
    try {
      switch (response.status) {
        case 200:
          OKResponsePay(response.data.data.data[0].comprobante);
          break;
        case 201:
          OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
          break;
        case 202:
          OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
          break;
        case 400:
          FailResponsePay("Your payment record was declined");
          //FailResponsePay(txt[48]);
          break;
        default:
          FailResponsePay("Your payment record was declined");
          //FailResponsePay(txt[48]);
          break;
      }
    } catch (e) {
      console.error("Error en manageResponse:", e);
      FailResponsePay();
    }
  };

  const loadUserData = async (doc) => {
    try {
      const Payload = {
        ...doc,
        card: null,
        transaction_id: tokenid,
        manual: false,
        coment: "",
        voucherlink: "",
      };
      readDocumentById("logged_user").then((user) => {
        datacontext.UpdateData(doc);
        if (user) {
          datacontext.setLoggedUser(user);
          datacontext.UpdateTerminal(user.Terminal);
        }

        datacontext.postPayment(
          manageResponse,
          Payload,
          1,
          user ? user.token : null
        );
      });
    } catch (error) {
      console.error("Error en loadUserData:", error);
      FailResponsePay();
    }
  };

  const CompletePayment = () => {
    try {
      readDocumentById(dbId).then((doc) => {
        datacontext.UpdateData(doc);
        loadUserData(doc);
      });
    } catch (error) {
      console.error("Error en CompletePayment:", error);
      FailResponsePay();
    }
  };

  const configureApi = () => {
    setState(1);
  };
  const makePayment = () => {
    setState(2);
  };
  const CompletePay = (token, manual = false, coment, picture) => {
    try {
      if (manual) {
        CompleteManualTransaction(token, coment, picture);
        setTokenid(null);
      } else {
        setTokenid(token);
      }
    } catch (error) {
      console.error("Error en CompletePay:", error);
      FailResponsePay();
    }
  };

  useEffect(() => {
    try {
      if (
        window.OpenPay &&
        openpayOptions.openpayid != null &&
        openpayOptions.apikeyopenpay != null
      ) {
        window.OpenPay.setId(openpayOptions.openpayid);
        window.OpenPay.setApiKey(openpayOptions.apikeyopenpay);
        window.OpenPay.setSandboxMode(openpayOptions.sandboxmode);
        const deviceDataId = window.OpenPay.deviceData.setup("paymentForm");
        setDeviceSessionId(deviceDataId);
      }
    } catch (error) {
      console.error("Error en useEffect para OpenPay:", error);
      FailResponsePay();
    }
  }, [openpayOptions]);

  useEffect(() => {
    if (source_id != null) {
      setState(3);
    }
  }, [source_id]);

  const CompleteManualTransaction = (manualid, coment, picture) => {
    const Payload = {
      ...datacontext.Data.Datos,
      card: null,
      transaction_id: manualid,
      manual: manualid.length < 15 ? true : false,
      coment: coment,
      voucherlink: "",
    };
    const Complete = (Payload) => {
      try {
        if (manualid != null) {
          datacontext.postPayment(
            manageResponse,
            Payload,
            1,
            datacontext?.LoggedUser?.token
          );
          console.log();
        }
      } catch (e) {
        console.error("Error en useEffect para ManualID:", e);
        FailResponsePay("Fail to register payment please contact with support");
      }
    };
    const UploadVoucher = (storage)=>{
      const filepath = datacontext.Sandbox? "PhysicalVouchersSandox/" + manualid:"PhysicalVouchers/" + manualid
      uploadFile(picture, filepath,storage)
        .then((resp) => Complete({...Payload,voucherlink:resp}))
        .catch((e) => {console.log(e);Complete(Payload);})
    }
    
    const FirebaseConfigure = (res)=>{
      try{
        const firebaseConfig = res.data.data.firebaseConfig;
        const firebaseUser =atob(res.data.data.userFirebase) ;
        const firebasePasword = atob(res.data.data.paswordFirebase);
        const {storage, auth}=ConfigureFirebase(firebaseConfig);
        authenticateWithEmailAndPassword(firebaseUser,firebasePasword, auth).then(()=>{
          UploadVoucher(storage);
        }).catch((e) => {console.log(e);Complete(Payload);})
        
      }
      catch(e){
        console.log("Fallo al configurar firebase",e);
        Complete(Payload);
      }
    }
    
    if (picture) {
       datacontext.getFirebaseConfig(FirebaseConfigure,FirebaseConfigure);
        return;
    }
    Complete(Payload);
  };

  useEffect(() => {
    try {
      if (tokenid != null) {
        setState(4);
      }
    } catch (e) {
      console.log(e);
    }
  }, [tokenid]);

  useEffect(() => {
    switch (state) {
      case 1:
        ConfigureApiOpenPay();
        break;
      case 2:
        CreateToken();
        break;
      case 3:
        AskFor3ds();
        break;
      case 4:
        CompletePayment();
        break;
    }
  }, [state]);

  return {
    configureApi,
    makePayment,
    CompletePay,
  };
};
