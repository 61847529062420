import IncidentsComponent from "./components/Incidents/Incidents";
import Vouchers from "./components/Vouchers/Vouchers";
import Transactions from "./components/Transactions/Transactions";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh"; // Importar el ícono de Refresh
import { useState } from "react";
import UseContentDashboard from "../hooks/UseContentDashboard";

const Analytics = ({ filter = "today", specificfilter = null }) => {
  const [value, setValue] = useState("transactions");
  const {
    Refunds,
    Incidents,
    alltransactionsactive,
    setIncidents,
    makeRequest,
    Pagination,
    TransactionsList,
    TransactionDetailsList,
    PayementsVouchers,
    PayementsVouchersCompleted,
    LotClouser
  } = UseContentDashboard({ filter, specificfilter });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            maxWidth: "calc(100% - 50px)", // Deja espacio para el botón de refrescar en la derecha
          }}
        >
          <Tab value="transactions" label="Transacciones" />
          <Tab value="incidents" label="Incidencias" />
          <Tab value="phisicalregist" label="Registros físicos" />
        </Tabs>

        <IconButton color="primary" onClick={makeRequest} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        {value === "transactions" && (
          <Transactions
            contentdashboard={{
              TransactionsList,
              alltransactionsactive,
              TransactionDetailsList,
              Pagination,
            }}
          />
        )}
        {value === "incidents" && (
          <IncidentsComponent
            contentdashboard={{
              Refunds,
              Incidents,
              alltransactionsactive,
              setIncidents,
              makeRequest,
              Pagination,
            }}
          />
        )}
        {value === "phisicalregist" && (
          <Vouchers
            contentdashboard={{
              alltransactionsactive,
              PayementsVouchers,
              PayementsVouchersCompleted,
              LotClouser,
              Pagination,
              makeRequest,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default Analytics;
