import { useState, useEffect, useContext } from "react";
import { Context } from "../../../../../../../../context/Context";
import UseContentDashboard from "../../../../hooks/UseContentDashboard";

const useTransactions = ({contentdashboard}) => {
  const datacontext = useContext(Context);
  const {
    TransactionsList,
    alltransactionsactive,
    TransactionDetailsList,
    Pagination,
  } = contentdashboard;

  const [transactiondata, seTransactiondatavoucher] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
    paymentgateway: "",
  });

  const [openPayloadViewer, setopenPayloadViewer] = useState(false);
  const [emailSendStatus, setEmailSendStatus] = useState(null);

  useEffect(() => {
    if (transactiondata.payload) setopenPayloadViewer(true);
  }, [transactiondata]);

  const handleTransactionSelected = (row) => {
    seTransactiondatavoucher({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
      paymentgateway: row.openpayKey,
    });
  };

  const handleClose = () => {
    seTransactiondatavoucher({
      payload: "",
      transactionid: "",
      voucher: "",
      paymentgateway: "",
    });
    setopenPayloadViewer(false);
  };

  const onResenEmailClicked = (data) => {
    const EmailData = {
      voucher: data.voucher,
      email: data.newPayload.correo,
      importe: data.newPayload.importe,
      service_charge: data.newPayload.service_charge,
    };
    datacontext.postForwardEmail(
      () => {
        setEmailSendStatus(true); // Éxito
      },
      () => {
        setEmailSendStatus(false); // Error
      },
      EmailData
    );
  };

  const handlePageChange = (newPage) => {
    Pagination(newPage);
  };

  const keysEditablesTransactions = ["correo", "telefono"];
  const keysVisiblesTransactions = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
    "coment"
  ];

  return {
    TransactionsList,
    alltransactionsactive,
    TransactionDetailsList,
    transactiondata,
    openPayloadViewer,
    emailSendStatus,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
    onResenEmailClicked,
    handlePageChange,
    setEmailSendStatus,
  };
};

export default useTransactions;
