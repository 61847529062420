import { useState, useEffect, useContext } from "react";
import { uploadFile } from "../../../../../../../../context/firebase/storageCrud";
import { ConfigureFirebase,authenticateWithEmailAndPassword } from "../../../../../../../../context/firebase/firebaseconfig";

import { Context } from "../../../../../../../../context/Context";
;

const useVouchers = ({ contentdashboard }) => {
  const datacontext = useContext(Context);
  const [vouucherdata, setvoucherdata] = useState(null);
  const [voucherstatus, setvoucherstatus] = useState(null);
  const [openVoucherViewer, setOpenModalViewer] = useState(false);
  const { alltransactionsactive, PayementsVouchers,PayementsVouchersCompleted, Pagination, makeRequest ,LotClouser} = contentdashboard // El hook que manejas para el dashboard

  useEffect(() => {
    if (vouucherdata) setOpenModalViewer(true);
  }, [vouucherdata]);

  useEffect(() => {
    console.log(LotClouser);
  }, [LotClouser]);

  const handleOpenVoucherPicture = (row) => {
    setvoucherdata(row);
  };

  const handleVoucherModalSubmit = (imageFile, coment, voucher) => {

    const onOkresponse = () => {
      setvoucherstatus(true);
      makeRequest();
    };

    const onFailResponse = (resp) => {
      setvoucherstatus(false);
      console.log("El fallo es en el update picture", resp)
    };

    const UpdatePicture = (url) => {
      const payload = { ...voucher, imageUrl: url, description: coment };
      datacontext.putPhysicalRecords(onOkresponse, onFailResponse, payload);
    };

    const UploadVoucher = (storage) => {
       let filepath;
      if (voucher?.paymentGateway) {
        filepath = datacontext.Sandbox
          ? "PhysicalVouchersSandox/" + voucher?.paymentGateway
          : "PhysicalVouchers/" + voucher?.paymentGateway;
      } else {
        filepath = datacontext.Sandbox
          ? "LotClousersSandox/" + voucher?.dateCreate
          : "LotClousers/" + voucher?.dateCreate;
      }
 
        uploadFile(
          imageFile,
          filepath,
          storage
        )
          .then((resp) => UpdatePicture(resp))
          .catch((e) => {
            console.log(e);
            console.log("Esta Fallo al subir la imagen por alguna razon, aqui dentro", e)
            onFailResponse();
          });
    };

    const FirebaseConfigure = (res) => {
      try {
        const firebaseConfig = res.data.data.firebaseConfig;
        const firebaseUser =atob(res.data.data.userFirebase) ;
        const firebasePasword = atob(res.data.data.paswordFirebase);
        const {storage, auth}=ConfigureFirebase(firebaseConfig)
        authenticateWithEmailAndPassword(firebaseUser,firebasePasword, auth)
          .then(() => {
            UploadVoucher(storage);
          })
          .catch((e) => {
            console.log(e);
            console.log("Esta Fallo al subir la imagen por alguna razon", e)
            onFailResponse();
          });
      } catch (e) {
        console.log("Fallo al configurar firebase", e);
        onFailResponse();
      }
    };
    if(voucher){
      datacontext.getFirebaseConfig(FirebaseConfigure, FirebaseConfigure);
    }
    else {
      console.log("Esta entrando aqui");
      datacontext.postLotClosures(resp=>{
        console.log("Todo paso correctamente");
        console.log(resp.data.data);
         handleVoucherModalSubmit(imageFile,coment,resp.data.data);
      },
      resp=>{console.log("Esta entrando en el Fail Response", resp);onFailResponse()}
      ,
      {description:coment,imageUrl:"",idUser:datacontext.LoggedUser.id})
    }

  };

  return {
    vouucherdata,
    voucherstatus,
    openVoucherViewer,
    PayementsVouchers,
    PayementsVouchersCompleted,
    alltransactionsactive,
    Pagination,
    LotClouser,
    handleOpenVoucherPicture,
    handleVoucherModalSubmit,
    setOpenModalViewer,
    setvoucherstatus,
    setvoucherdata,
  };
};

export default useVouchers;
